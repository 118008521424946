import { useNavigate, Router as R, RouterProps, useParams, useSearchParams, useLocation } from "@solidjs/router";
import { LocationChangeSignal } from "@solidjs/router";
import { createEffect, createRenderEffect, createRoot, on, splitProps, untrack } from "solid-js";
import { $route, $route_params } from "../store";
import { unwrap } from "solid-js/store";

//! don't use any router specific states outside of R.
export function Router(props: RouterProps) {
  const [local, others] = splitProps(props, ["children"]);
  function RouterHelpers() {
    const $location = useLocation();
    const navigate = useNavigate();
    const [$search_params, $params_actions] = useSearchParams();
    let after_navigate_funcs = undefined as (() => void)[];

    createEffect(
      on($route.accessor, (data) => {
        untrack(() => {
          // const [my_options, navigate_options] = splitProps(data.options, ["afterNavigate"]);
          // console.log("router myoptions :: ", my_options, navigate_options);
          if (data) {
            // console.log("sad ::: ", unwrap($search_params));
            if (data.delta) {
              navigate(data.delta);
            } else {
              navigate(data.to, data.options);
            }
            if (!after_navigate_funcs) after_navigate_funcs = [];
            if (data.options?.afterNavigate) after_navigate_funcs.push(data.options?.afterNavigate);
            // $route.set(undefined);
          }
        });
      })
    );

    createEffect(
      on($route_params.accessor, (data) => {
        untrack(() => {
          if (data) {
            // console.log("data from log is :: ", data);
            $params_actions(data.params, data.options);
            // $route_params.set(undefined);
          }
        });
      })
    );
    createRenderEffect(() => {
      console.log(
        `// ============================================== NAVIGATION OCCURED TO ${$location.pathname} ================================== \\\\`
      );
      after_navigate_funcs?.forEach((f) => f());
      after_navigate_funcs = undefined;
    });
    return null;
  }
  return (
    <R {...(others as any)}>
      <RouterHelpers />
      {local.children}
    </R>
  );
}
